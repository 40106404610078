<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    :fullscreen="isMobile"
    style="background: white"
  >
    <v-card>
      <v-card-title v-if="dialog">
        {{ giftCardParams && giftCardParams.title ? giftCardParams.title : "" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="mUpdateGiftCardDialog(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

     <!--  <stepper :params="{ detail: giftCardParams }" :key="giftCardKey" v-if="giftCardKey" /> -->
      <stepper :params="{ detail: giftCardParams }" :key="giftCardKey" />
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapMutations, mapState } = createNamespacedHelpers("giftcard");

export default {
  data: () => ({
    drawer: null,
    pageLoad: true,
    localLoading: false,
    loading: false,
    amount: 50,
    currency: "GBP",
    pageLoaded: false,
    email: null,
    fullname: null,
    showSuccess: false,
  }),
  computed: {
    ...mapState({
      giftCardParams: (state) => state.giftCardParams,
      giftCardDialog: (state) => state.giftCardDialog,
      giftCardKey: (state) => state.giftCardKey
    }),
    layout() {
      return this.$store.state.layout;
    },
    creatingIntent() {
      return this.$store.state.creatingIntent;
    },
    dialog: {
      get() {
        return this.giftCardDialog;
      },
      set(value) {
        this.mUpdateGiftCardDialog(value);
      },
    },
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
  },
  components: {
    Stepper: () => import("@/components/giftcard/stepper"),
  },
  methods: {
    ...mapMutations(["mUpdateGiftCardDialog"]),
  },
  mounted() {},
};
</script>
<style scoped>
.card-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
}
</style>
