<template>
  <v-snackbar top v-model="snackbar">
    <div> {{ message }} </div>
    <v-btn color="pink" class="float-right" text @click="closeAlert"> Close </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  name: "AppAlert",
  data() {
    return {};
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set() {
        this.$store.commit("mCloseAlert");
      },
    },
    message() {
      return this.$store.state.snackbarMessage;
    },
  },
  methods: {
    closeAlert() {
      this.$store.commit("mCloseAlert");
    },
  },
};
</script>
`