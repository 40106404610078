import ApiService from "./api.service";

const PayService = {
  createIntent(payload) {
    return ApiService.post("/pay/create-payment-intent", payload);
  },
  customer() {
    return ApiService.post("/pay/customer", {});
  },
  getCards() {
    return ApiService.post("/pay/payment-methods", {});
  },
  confirmIntent(payload) {
    return ApiService.post("/pay/confirm-payment", payload);
  },
  confirm(payload) {
    return ApiService.post("/service/nawec-test/confirm", payload);
  },
  getIntent(secret) {
    return ApiService.query(`/pay/intent/${secret}`);
  },
  sendSMS(payload) {
    return ApiService.post("/service/nawec-test/action", payload);
  },
  getTransactions(code, transactionType) {
    const path = transactionType && transactionType !== "DEFAULT" ? `/wallet/list/transactions/${code}/${transactionType}` : `/wallet/list/transactions/${code}`;
    return ApiService.query(path);
  },
  getPayOuts() {
    return ApiService.query("/wallet/payouts/", {});
  },

  getPendingTransactions(walletCode){
    //const path= `/wallet/report/my_pending_payment_transactions/${walletCode}`;
    //return ApiService.query(path);
    return ApiService.query(`/wallet/report/my_pending_payment_transactions/${walletCode}`);
  },
  
  getPayOutUsingWithdrawalCode(withdrawalCode) {
    return ApiService.query("/wallet/payout/withdraw/" + withdrawalCode, {});
  },
  getPayOutUsingSecret(secretCode){
    return ApiService.query(`/wallet/payout/${secretCode}`);
  },
  getWalletUsingCode(code){
    return ApiService.query(`/wallet/${code}`);
  },
  confirmWithdrawal(payload) {
    return ApiService.post("/wallet/payout/withdraw", payload);
  },
  confirmPayOutTransfer(payload) {
    return ApiService.post("/wallet/payout/transfer", payload);
  },
  confirmWalletTransaction(payload) {
    return ApiService.post("/pay/wallet/confirm-transaction", payload);
  },
  payInvoice(payload) {
    return ApiService.post("/pay/wallet/invoice", payload);
  },
  paymentCalculator(payload) {
    return ApiService.post("/v2/money-transfer/calculate", payload);
  },

  getWalletList(){
    return ApiService.query("/p/pay/config/services");
  },
  createWallet(payload){
    return ApiService.post("/wallet", payload)
  },
  createPaymentGroup(payload){
    return ApiService.post("/pay/group", payload)
  },

  addTag(payload){
    return ApiService.post("/pay/tags", payload)
  },
  updateTag(id, payload){
    return ApiService.put(`/pay/tags/${id}`, payload)
    
  },
  deleteTag(id){
    return ApiService.delete(`/pay/tags/${id}`)
  },
  addBank(payload){
    return ApiService.post("/pay/account/external", payload)
  },
  updateBank(payload){
    return ApiService.put("/pay/account/external", payload)
  },
  listPaymentGroups(walletCode, service){
    if(service) {
      return ApiService.query(`/pay/group/list/${walletCode}/${service.toUpperCase()}`);
    }
    return ApiService.query(`/pay/group/list/${walletCode}`);
  },
  getPaymentGroup(code){
    return ApiService.query(`/pay/group/${code}`)
  },

  updatePaymentGroup(payload){
    return ApiService.put(`/pay/group`, payload)
  },
  deletePaymentGroup(code){
    return ApiService.delete(`/pay/group/${code}`)
  },
  createPaymentGroupBeneficiary(payload){
    return ApiService.post("/pay/group/beneficiary", payload)
  },
  uploadBeneficiaries(code, data){
    return ApiService.post(`/pay/group/beneficiary/upload/${code}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadStatement(accountCode, data){
    return ApiService.post(`/pay/account/external/statement/upload/${accountCode}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  })
},
  runGroupPayment(payload){
    return ApiService.post("/pay/group/run", payload)
  },

  getPaymentGroupBeneficiaries(code){
    return ApiService.query(`/pay/group/beneficiary/list/${code}`)
  },
  deleteBeneficiary(code) {
    return ApiService.delete("/pay/group/beneficiary/" + code);
  },

  getLoan(code){
    return ApiService.query(`/loan/${code}`)
  },

  updateLoan(payload){
    return ApiService.post("/loan/update/status", payload)
  },

  walletTransfer(payload){
    return ApiService.post("/wallet/transfer", payload)
  },
  setPayoutCustomerServiceStatus(payload) {
    return ApiService.post("/wallet/payout/update-cs-status", payload)
  },
  updateRecipient(payload) {
    return ApiService.post("/wallet/payout/update-recipient", payload)
  },
  approve(payload) {
    return ApiService.post("/pay/approve", payload);
  },
  cancel(payload) {
    return ApiService.post("/pay/cancel", payload);
  },
  confirmPayment(payload) {
    return ApiService.post("/pay/confirm-payment", payload);
  },
  getWhatsAppConfig() {
    return ApiService.query(`/pay/whatsapp/config`);
  },
  deleteErrors(payload) {
    return ApiService.post("/pay/group/delete-errors", payload);
  },
  deleteDuplicates(payload) {
    return ApiService.post("/pay/group/delete-duplicates", payload);
  },
  deleteAll(payload) {
    return ApiService.post("/pay/group/delete-all", payload);
  },

  addConfig(payload) {
    return ApiService.post("/pay/config", payload);
  },
  approveRequest(payload) {
    return ApiService.post("/pay/request/approve", payload);
  },
  rejectRequest(payload) {
    return ApiService.post("/pay/request/cancel", payload);
  },
};

export default PayService;
