<template>
  <span style="display: none"></span>
</template>
<script>
export default {
  name: "InitComponent",
  data() {
    return {};
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    this.$store.dispatch("aInit");
  }
};
</script>
`