<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title><v-icon>receipt</v-icon> Transaction for {{detail.name}} </v-card-title>
        <!-- <v-card-text> -->
        <v-container>
          <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Status</td>
          <td>completed</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>completed</td>
        </tr>
        <tr>
          <td>To</td>
          <td>completed</td>
        </tr>
        <tr>
          <td>Meter Number</td>
          <td>completed</td>
        </tr>
        <tr>
          <td>Token</td>
          <td>completed</td>
        </tr>
        <!-- <tr>
          <td>status</td>
          <td>completed</td>
        </tr> -->
        <tr>
          <td></td>
          <td>
              <v-btn class="ma-2" outlined color="green"> Buy Again </v-btn>
          <v-btn class="ma-2" outlined color="red"> Resend </v-btn>
          </td>
        </tr> 
      </tbody>
    </template>
  </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { validatePhoneNumber, getAirtimeNumberType } from "../../services/lib";
import { createNamespacedHelpers } from "vuex";

const basketNamespace = createNamespacedHelpers("basket");
const airtimeNamespace = createNamespacedHelpers("airtime");
const transactionsNamespace = createNamespacedHelpers("transaction");

// import { createPaymentRequest } from "@/lib/payment";

const paymentNamespace = createNamespacedHelpers("payment");
export default {
  data: () => ({
    amount: "",
    detail: null,
    error: false,
    valid: true,
  }),

    computed: {
      ...transactionsNamespace.mapState({
      transactionType:(state)=>state.transactionType,
      transactionsDialog:(state)=>state.transactionsDialog,
      transactionsParam:(state)=>state.transactionsParam,
    }),
    ...airtimeNamespace.mapState({
      airtimeDialog: (state) => state.airtimeDialog,
      airtimeParams: (state) => state.airtimeParams,
    }),
    dialog: {
      get() {
        return this.transactionsDialog;
      },
      set(value) {
        this.mUpdateTransactionDialog(value);
      },
    },
    // mobile() {
    //     return this.airtimeParams && this.airtimeParams.mobile ? this.airtimeParams.mobile : ''
    // }
    

  },
  mounted() {
      this.detail = { ...this.transactionsParam }
      if((typeof this.detail.mobile == "undefined" || !this.detail.mobile) && this.detail.description){
      this.detail.mobile = (this.detail.description.substring(8)).trim()
      console.log('mobile',this.detail.mobile)
      }
  },
  methods: {
    ...paymentNamespace.mapActions(["aCreatePaymentIntent"]),
    ...paymentNamespace.mapMutations(["mStartPayment"]),
    ...basketNamespace.mapActions(["aSetDetails"]),
    ...airtimeNamespace.mapMutations(["mBuyAirtime", "mUpdateAirtimeDialog"]),
    ...airtimeNamespace.mapActions(["createAirtimePaymentIntent"]),
    ...transactionsNamespace.mapActions(["aLoadTransactionDetails"]),
    ...transactionsNamespace.mapMutations(["mUpdateTransactionDialog"]),
  },
};
</script>