import Vue from "vue";
// import vuetify from "@/plugins/vuetify";
// import VueI18n from "vue-i18n";

// import ApiService from "@/services/api.service";
// import JwtService from "@/services/jwt.service";
// import UserService from "@/services/users.service.js";
// import VueMq from "vue-mq";
import VueLogger from "vuejs-logger";
// import Vue2Filters from "vue2-filters";
// import VueMeta from "vue-meta";


import * as dayjs from "dayjs";

// Vue.use(VueMeta, {
//   // optional pluginOptions
//   refreshOnceOnNavigation: true,
// });

// Vue.use(Vue2Filters);

Vue.filter("date", function(value, format) {
  return dayjs(value).format(format ? format : "DD/MM/YYYY");
});

// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === "production";

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

// import "vuetify/src/styles/styles.sass";

// // import '@/vuetify/styles/settings/_colors.scss';
// // import '@/vuetify/src/styles/styles.sass'

// import "./assets/transition.scss";

// import "./assets/vuetify-overide.scss";

// import "./assets/vendors/animate.css";

// import "./assets/vendors/animate-extends.css";

// import "./assets/vendors/hamburger-menu.css";

// import "./assets/vendors/slick-carousel/slick.css";

// import "./assets/vendors/slick-carousel/slick-theme.css";

// import "./assets/vendors/slick-carousel/slick-theme.css";
// import "./assets/vendors/animate.css";
// import "./assets/vendors/animate-extends.css";

// import { VueMaskDirective } from 'v-mask';
// // import VueMask from 'v-mask';
// import '@mdi/font/css/materialdesignicons.css';
// import PerfectScrollbar from 'vue2-perfect-scrollbar';
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
// import ECharts from 'vue-echarts';
// import 'echarts';
// import CKEditor from 'ckeditor4-vue';
// import Fragment from 'vue-fragment';

// import 'vue2-datepicker/index.css';

// import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
// import 'vue-datetime/dist/vue-datetime.css';

// import EchartAll from 'echarts/core/a';

// import ECharts modules manually to reduce bundle size
// import {
//   CanvasRenderer
// } from 'echarts/renderers'
// import {
//   BarChart
// } from 'echarts/charts'
// import {
//   GridComponent,
//   TooltipComponent
// } from 'echarts/components'

// use([
//   CanvasRenderer,
//   BarChart,
//   GridComponent,
//   TooltipComponent
// ]);

// register globally (or you can do it locally)

// import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js';
// import { required, min, max, email } from 'vee-validate/dist/rules.esm.js';
// import veeEn from 'vee-validate/dist/locale/en';

// // Add the rules you need.
// Validator.extend('required', required);
// Validator.extend('min', min);
// Validator.extend('max', max);
// Validator.extend('email', email);
// // Merge the messages.
// Validator.localize('en', veeEn);

// Vue.use(VeeValidate);

// import VeeValidate from "vee-validate";
// import { messages } from "@tenant/static/lang";

// import { Plugin } from "vue-fragment";
// import VueScrollactive from "vue-scrollactive";

// import options from "vue-countup-directive/src";

// import VueWow from "vue-wow";

// // mount with global
// Vue.use(VueWow);

// Vue.directive("countUp", options);

// // use default options
// Vue.use(VueScrollactive);


// Vue.use(Plugin);

// Vue.use(VeeValidate);
// Vue.use(VueI18n);
// Vue.use(VueMq, {
//   // Default breakpoint for SSR
//   defaultBreakpoint: "default",
//   breakpoints: {
//     xsDown: 599,
//     xsUp: 600,
//     smDown: 959,
//     smUp: 960,
//     mdDown: 1279,
//     mdUp: 1280,
//     lgDown: 1919,
//     lgUp: 1920,
//     xl: Infinity,
//   },
// });

// const i18n = new VueI18n({
//   locale: "en", // set locale
//   messages, // set locale messages
// });

import VueProgressBar from "vue-progressbar";
// Vue.use(VueHtml2Canvas);

Vue.use(VueProgressBar, {
  color: "blue",
  failedColor: "red",
  height: "20px",
});

// import { store } from "./store/store";
// import router from "./router";

// import App from "./App.vue";

// Vue.use(Datetime);

// Vue.directive('mask', VueMaskDirective);
// // Vue.use(VueMask);
// Vue.use(Fragment.Plugin);
// Vue.use(CKEditor);
// Vue.component('VChart', ECharts);
// // Vue.component('Datetime', Datetime);
// Vue.use(PerfectScrollbar);

// Vue.config.productionTip = false;

// ApiService.init();

// const checkAPIAuth = async (next, to) => {
//   try {
//     await UserService.status();
//   } catch (error) {
//     try {
//       if (error.response.status == 403) {
//         JwtService.destroyToken();
//         if (to.matched.some((record) => record.meta.requiresAuth)) {
//           next({
//             name: "LogIn",
//             params: { nextUrl: to.fullPath },
//           });
//         }
//       }
//     } catch (err) {
//       //
//     }
//   }
// };

// router.beforeEach((to, from, next) => {
//   Promise.all([store.dispatch("users/aCheckAuth")]).then(function() {
//     const timeout = setTimeout(() => {
//       checkAPIAuth();
//       clearTimeout(timeout);
//     }, 2000);

//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//       const user = JwtService.getUser();

//       if (JwtService.getToken() == null || user == null) {
//         next({
//           name: "LogIn",
//           params: { nextUrl: to.fullPath },
//         });
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   });

//   // Promise.all([store.dispatch('users/aCheckAuth')]).then(next)
// });

// new Vue({
//   i18n,
//   router,
//   store,
//   vuetify,
//   render: (h) => h(App),
// }).$mount("#app_main");

console.log("admin-js-loaded");