/* eslint-disable no-undef */


import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
// import DomainService from "./domain.service";
import { API_URL, TENANT } from "@/config";
import { version } from "../../package.json";


const ApiService = {
  axiosInstance: null,
  init() {
    
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common['TENANT'] = TENANT;
    Vue.axios.defaults.headers.common['CLIENT_VERSION'] = version;
    Vue.axios.defaults.headers.common['CLIENT_PLATFORM'] = 'WEB';
    Vue.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status == 403) {
            try {
              // JwtService.destroyToken();
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        return Promise.reject(error);
      }
    );
    this.axiosInstance = Vue.axios;
  },
  getBaseUrl() {
    return API_URL;
  },
  getHeader() {
    return {
      Authorization: `Bearer ${JwtService.getToken()}`,
    };
  },
  removeHeader(key) {
    try {
      delete Vue.axios.defaults.headers.common[key];
    } catch (error) {
      //
    }
  },
  setHeader(token) {
    // console.log("setHeader");
    const t = token ? token : JwtService.getToken();
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${t}`;
  },
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },
  getDownload(resource, params) {
    return Vue.axios.get(resource, { responseType: 'blob', params} );
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.patch(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};


export default ApiService;
export { API_URL };
