<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title><v-icon>bolt</v-icon> Select Wallet </v-card-title>
        <v-card-text>
          <v-simple-table class="transactions">
            <!-- cashpower contacts -->
            <tbody v-if="wallets">
              <tr
                v-for="wallet in wallets"
                :key="wallet.id"
                style="height: 80px"
                @click="mcloseWalletDialog(wallet)"
              >
                <td>
                  <v-avatar color=" ma-1">
                    <v-icon size="40" color="purple lighten-1"
                      >{{ wallet.icon }}</v-icon
                    >
                  </v-avatar>
                  {{ wallet.name }}
                </td>

                <!-- <td class="text-left">{{ meter.name }}</td> -->
                <td class="text-right">
                  {{ wallet.prettyValue }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const usersNamespace = createNamespacedHelpers("users");
const cashpowerNamespace = createNamespacedHelpers("cashpower");
export default {
    mounted() {
        console.log('load wallet')
    },
    computed: {
        ...cashpowerNamespace.mapState({
      cashpowerDialog: (state) => state.cashpowerDialog,
    }),
        ...usersNamespace.mapState({
      wallets: (state) => state.wallets,
      walletDialog: (state) => state.walletDialog,
    }),
    dialog: {
      get() {
        return this.cashpowerDialog;
      },
      set(value) {
          this.mUpdateWalletDialog(value);
      }
    },
    },
    methods: {
        ...usersNamespace.mapMutations(["mcloseWalletDialog", "mUpdateWalletDialog"]),
    }
}
</script>

<style>

</style>