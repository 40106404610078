<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title>
          <h2 class="subtitle-1">Airtime Top-Up</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="mUpdateAirtimeDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- <v-card-title>Buy Cash Power for {{ detail.name }}</v-card-title> -->
        <!-- <v-card-text> -->
        <Stepper :params="{ detail: airtimeParams }" />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const airtimeNamespace = createNamespacedHelpers("airtime");
// import { devNull } from 'os';
export default {
  components: {
    Stepper: () => import("@/components/airtime/buy-stepper"),
  },
  computed: {
    ...airtimeNamespace.mapState({
      airtimeDialog: (state) => state.airtimeDialog,
      airtimeParams: (state) => state.airtimeParams,
    }),
    dialog: {
      get() {
        return this.airtimeDialog;
      },
      set(value) {
        this.mUpdateAirtimeDialog(value);
      },
    },
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
  },
  methods: {
    ...airtimeNamespace.mapMutations([
      "mBuyAirtime",
      "mUpdateAirtimeDialog",
      "mResetParams",
    ]),
  },
  mounted() {},
};
</script>