import ApiService from "./api.service";

const ReportService = {
  getReport(reportName, p) {
    const params = p ? p : {};
    params.reportName = reportName;
    return ApiService.query("/wallet/report", { params });
  },
  async download(reportName, p, fileName) {
    const params = p ? p : {};
    params.reportName = reportName;
    // return ApiService.getDownload("/wallet/download", params );

    try {
      const response = await ApiService.axiosInstance.get('/wallet/download', {
          params: params, // Pass query parameters here
          responseType: 'blob', // Set response type to blob for file download
      });

      // Create a blob from the response
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Generate a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;

      // Set the filename (optional)
      // const currentDateTime = new Date().toISOString().replace(/[:.]/g, '-');
      link.download = `${fileName}.csv`;

      // Append the link, trigger the click, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the URL after download
      window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error('Error downloading the report:', error);
  }

    
  },
  
};
export default ReportService;
