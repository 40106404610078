//import Factory from '@/services/factory';
//import PublicPayService from '@/services/public-pay.service';
//import PayService from '../../../services/pay.service';
import ReportService from "../../../services/report.service";

export default {
  async aGetExchangeRateConfigs({state}) {
  try{
    const response = await ReportService.getReport("pay_configs", {
      group_code : 'EXCHANGE-RATE-CONFIG'
    });
    const data = response.data.items;
    state.exchangeRateConfigs = data;
    console.log("aGetExchangeRateConfigs data", data);
  }catch(error){
    this.$store.commit("mShowAlert", error);
  }
},






/*   async aGetExchangeRateConfigs({ state }) {
    try {
      const response = await ReportService.getReport("pay_configs", {
        group_code: "EXCHANGE-RATE-CONFIG",
      });
      const data = response.data.items;
      const parsedData = data.map((item) => ({
        ...item,
        content: JSON.parse(item.content),
      }));
      state.exchangeRateConfigs = parsedData;
      console.log("Parsed Exchange Rate Configs", parsedData);
    } catch (error) {
      this.$store.commit("mShowAlert", error);
    }
  }, */
};
