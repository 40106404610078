import JwtService from '@/services/jwt.service';

import { getUserModules } from '@/lib/user';

export default {
  mResetPayment(state) {
    state.paymentParams = null;
    state.paymentID = null;
  },
  mSetFromCurrencyCode(state, currencyCode) {
    state.fromCurrencyCode = currencyCode;
    if(state.paymentParams) {
      state.paymentParams.fromCurrency = currencyCode;
      state.paymentParams.fromCurrencyCode = currencyCode;
    }
    // alert('setCurrencyCode');
  },
  mStartTopUp(state, { params }) {
    const payload = { ...params };
    const ui = {
      enterAmount: false,
    };

    if (payload.ui) {
      payload.ui = { ...ui, ...payload.ui };
    } else {
      payload.ui = ui;
    }

    if (!payload.toWalletCode) {
      payload.toWalletCode = this.state.users.wallet.code;
    }
    
    if (typeof payload.public === "undefined") {
      payload.public = this.state.users.wallet.public ? true : false;
    }
    
    if (!payload.userCode) {
      payload.userCode = this.state.users.wallet.userCode;
    }

    if (!payload.transactions) {
      payload.transactions = this.state.basket.intents.map((item) => item.secret);
    }
    
    state.topUpParams = payload;
    

    state.topUpKey = new Date().toLocaleString();
    
    state.topUpDialog = false;
    
    if(params.displayType && params.displayType === 'DIALOG') {
      state.topUpDialog = true;
    }
    // console.log('params', params);
  },
  mStartPayment(state, { params }) {
    const payload = { ...params };
    const ui = {
      enterAmount: false,
    };

    if (payload.ui) {
      payload.ui = { ...ui, ...payload.ui };
    } else {
      payload.ui = ui;
    }

    if (!payload.toWalletCode) {
      payload.toWalletCode = this.state.users.wallet.code;
    }
    
    if (typeof payload.public === "undefined") {
      payload.public = this.state.users.wallet.public ? true : false;
    }
    
    if (!payload.userCode) {
      payload.userCode = this.state.users.wallet.userCode;
    }

    if (!payload.transactions) {
      payload.transactions = this.state.basket.intents.map((item) => item.secret);
    }
    
    state.paymentParams = payload;
    

    state.paymentID = new Date().valueOf();

    state.paymentDialog = false;
    
    if(params.displayType && params.displayType === 'DIALOG') {
      state.paymentDialog = true;
    }
    // console.log('params', params);
  },
  

  mUpdateDialog(state, value) {
    state.paymentDialog = value;
  },
  mSetBeneficiaryDialog(state, value) {
    state.beneficiaryDialog = value;
  },
  mSetErroredDialog(state, value) {
    state.erroredDialog = value;
  },
 /*  mSetBeneficiary(state, value) {
    state.recipient = value;
  }, */
 /*  mSetSendMoneyPayload(state, value) {
    state.sendMoneyPayload = value;
  }, */

 /*  msetBeneficiaryDetails(state, value) {
    state.beneficiaryDetails = value;
  }, */

  mSetLoading(state, value) {
    state.loading = value;
  },
  mSetIsEditing(state, value) {
    state.isEditing = value;
  },
  mAddGroupDialog(state, value) {
    state.addGroupDialog = value;
  },
  mAddBankDialog(state, value) {
    state.addBankDialog = value;
  },
  mUpdateGroupDialog(state, value) {
    state.updateGroupDialog = value;
  },
  mSetPayGroup(state, value) {
    state.payGroup = value;
  },
  mUpdateTopUpDialog(state, value) {
    state.topUpDialog = value;
  },
  mSetAuth(state, user) {
    state.isAuthenticated = true;
    state.user = getUserModules(user);
    state.errors = {};
    JwtService.saveUser(state.user);
    JwtService.saveToken(state.user.token);
  },
  mSetToCurrencyCode(state, currencyCode) {
    state.toCurrencyCode = currencyCode;
    if(state.paymentParams) {
      state.paymentParams.toCurrency = currencyCode;
      state.paymentParams.toCurrencyCode = currencyCode;
    }
  },
  mPurgeAuth(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyUser();
  }
}
