<template>
  <v-app id="inspire">
    <sideNav />
    <v-main>
      <!-- class="py-8 px-6" -->
      <v-container :fluid="isMobile ? true : true">
        <router-view />
        <BuyCashpowerDialog
          v-if="cashpowerDialog && cashpowerParams && cashpowerDialogKey"
          :key="cashpowerDialogKey"
          :params="{ detail: cashpowerParams }"
        />
        <BuyAirtimeDialog
          v-if="airtimeDialog && airtimeDialogKey"
          :key="airtimeDialogKey"
          :params="{ detail: airtimeParams }"
        />
        <BuyGiftCardDialog
          v-if="giftCardDialog && giftCardDialogKey"
          :key="giftCardDialogKey"
          :params="{ detail: giftCardParams }"
        />
        <SellCashpowerDialog
          v-if="
            cashpowerSellDialog && cashpowerParams && cashpowerSellDialogKey
          "
          :key="cashpowerSellDialogKey"
          :params="{ detail: cashpowerParams }"
        />
        <SellAirtimeDialog
          v-if="airtimeSellDialog && airtimeSellDialogKey"
          :key="airtimeSellDialogKey"
          :params="{ detail: airtimeParams }"
        />
        <SendMoneyDialog
          v-if="sendmoneyDialog && sendmoneyDialogKey"
          :key="sendmoneyDialogKey"
          :params="{ detail: sendmoneyParams }"
        />
        <WalletsDialog v-if="WalletDialog" :key="walletDialogKey" />
        <WalletsDialog v-if="WalletDialog" :key="walletDialogKey" />
        <topup-dialog v-if="topUpDialog && topUpKey" :key="topUpDialog" />
        <switch-wallet />
        <transactionDialog
          v-if="transactionsDialog"
          :key="transactionsDialogKey"
        />
        <app-alert />
        <init />
        <!-- <v-snackbar
          v-model="identity.snackbar"
          :vertical="true"
          timeout="-1"
          color="warning"
          outlined
        >
          <div style="width: 100%">Your Identity Is Not Validated</div>
          <v-btn
            color="primary"
            style="float: right"
            @click="identity.snackbar = false"
          >
            Click To Validate
          </v-btn>
        </v-snackbar> -->
      </v-container>
    </v-main>

    <!--     <v-footer
      app
      color="transparent"
      height="72"
      inset
    >
      <v-text-field
        background-color="grey lighten-1"
        dense
        flat
        hide-details
        rounded
        solo
      />
    </v-footer> -->
  </v-app>
</template>
<style>
.v-application .v-snack__content {
  width: 100%;
}
</style>
<script>

// eslint-disable-next-line no-unused-vars
import * as admin from "@/admin-main.js";

import transactionDialog from "@/components/AdminComponent/transactionsDialog";
import BuyCashpowerDialog from "@/components/cashpower/dialog";
import BuyAirtimeDialog from "@/components/airtime/dialog";
import BuyGiftCardDialog from "@/components/giftcard/dialog";

import SellCashpowerDialog from "@/components/cashpower/sell-dialog";
import SellAirtimeDialog from "@/components/airtime/sell-dialog";

import SendMoneyDialog from "@/components/send-money/dialog";
import SwitchWallet from "@tenant/components/wallet/switch.vue";
import TopupDialog from "@/components/topup/dialog";
import WalletsDialog from "@/components/AdminComponent/walletsDialog";
const sideNav = () => import("@tenant/components/AdminComponent/side-nav.vue");
//const SwitchWallet = () => import("@tenant/components/AdminComponent/side-nav.vue");
import { createNamespacedHelpers } from "vuex";
const cashpowerNamespace = createNamespacedHelpers("cashpower");
const airtimeNamespace = createNamespacedHelpers("airtime");
const sendmoneyNamespace = createNamespacedHelpers("sendmoney");
const usersNamespace = createNamespacedHelpers("users");
const transactionNamespace = createNamespacedHelpers("transaction");
const paymentNamespace = createNamespacedHelpers("payment");
const giftcardNamespace = createNamespacedHelpers("giftcard");

import AppAlert from "@/components/alert/default";
import Init from "@/components/app/init";
//import state from '../store/modules/basket/state';

export default {
  components: {
    sideNav,
    BuyCashpowerDialog,
    BuyAirtimeDialog,
    SellCashpowerDialog,
    SellAirtimeDialog,
    WalletsDialog,
    SendMoneyDialog,
    AppAlert,
    transactionDialog,
    TopupDialog,
    SwitchWallet,
    Init,
    BuyGiftCardDialog,
  },
  computed: {
    ...transactionNamespace.mapState({
      transactionsParam: (state) => state.transactionsParam,
      transactionsDialogKey: (state) => state.transactionsDialogKey,
      transactionsDialog: (state) => state.transactionsDialog,
    }),
    ...paymentNamespace.mapState({
      paymentDialog: (state) => state.paymentDialog,
      paymentID: (state) => state.paymentID,
      topUpDialog: (state) => state.topUpDialog,
      topUpKey: (state) => state.topUpKey,
    }),
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
    ...cashpowerNamespace.mapState({
      cashpowerParams: (state) => state.cashpowerParams,
      cashpowerDialogKey: (state) => state.cashpowerDialogKey,
      cashpowerDialog: (state) => state.cashpowerDialog,
      cashpowerSellDialogKey: (state) => state.cashpowerSellDialogKey,
      cashpowerSellDialog: (state) => state.cashpowerSellDialog,
    }),
    ...airtimeNamespace.mapState({
      airtimeParams: (state) => state.airtimeParams,
      airtimeDialogKey: (state) => state.airtimeDialogKey,
      airtimeDialog: (state) => state.airtimeDialog,
      airtimeSellDialogKey: (state) => state.airtimeSellDialogKey,
      airtimeSellDialog: (state) => state.airtimeSellDialog,
    }),
    ...giftcardNamespace.mapState({
      giftCardParams: (state) => state.giftCardParams,
      giftCardDialogKey: (state) => state.giftCardDialogKey,
      giftCardDialog: (state) => state.giftCardDialog,
    }),
    ...sendmoneyNamespace.mapState({
      sendmoneyParams: (state) => state.sendmoneyParams,
      sendmoneyDialogKey: (state) => state.sendmoneyDialogKey,
      sendmoneyDialog: (state) => state.sendmoneyDialog,
    }),
    ...usersNamespace.mapState({
      WalletDialog: (state) => state.WalletDialog,
      walletDialogKey: (state) => state.walletDialogKey,
    }),
  },
  data: () => ({
    drawer: null,
    identity: {
      snackbar: true,
    },
  }),
  methods: {
    ...usersNamespace.mapActions(["aInit"]),
    removeChat() {
      try {
        const query = this.$route.query.chat;
        if(query === 'y') {
          window.HubSpotConversations.widget.open();
        } else {
          window.HubSpotConversations.widget.remove();
        }
      } catch(e) {
        //
      }
    }
  },
  mounted() {
    // alert("admin");
    this.aInit();
    this.$store.commit("mSetLayout", "admin");
    const timeout = setTimeout(() => {
      const preloader = document.getElementById("preloader");
      if (preloader !== null || undefined) {
        preloader.remove();
      }
      clearTimeout(timeout);
      that.removeChat();
    }, 200);

    const that = this;

    const timeout2 = setTimeout(() => {
      that.removeChat();
      clearTimeout(timeout2);
    }, 1000);

    const timeout3 = setTimeout(() => {
      that.removeChat();
      clearTimeout(timeout3);
    }, 5000);
  },
  beforeCreate() {},
};
</script>
