import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';
import basket from './modules/basket';
import breakpoints from './modules/breakpoints';
import payment from './modules/payment';
import contact from './modules/contact';
import config from './modules/config';
import nawec from './modules/nawec';
import kyc from './modules/kyc';
import transaction from './modules/transaction';
import cashpower from './modules/cashpower';
import sendmoney from './modules/sendmoney';
import airtime from './modules/airtime';
import payout from './modules/payout';
import report from './modules/report';
import locations from './modules/locations'
import giftcard from './modules/giftcard';
import identity from './modules/identity';
import commerce from './modules/commerce';
import ContactService from '@/services/contact.service';
import NAWECService from '@/services/nawec.service';
import AirtimeService from '@/services/airtime.service';
import ConfigService from '@/services/config.service';
import { getGeo } from '../services/geo.service';
import browser from 'browser-detect';
import { getFromStorage, saveInStorage } from '../services/localstorage.service';
import { GEO_DETAILS } from '../const/store';
import { getCurrency } from '../lib/currency'
import { getDialCode } from '../lib/geo';


Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    users,
    basket,
    payment,
    breakpoints,
    contact,
    config,
    cashpower,
    locations,
    airtime,
    sendmoney,
    nawec,
    transaction,
    payout,
    giftcard,
    kyc,
    report,
    identity,
    commerce,
  },
  state: {
    // contactType: null,
    // contacts: null,
    showHeader: true,
    previousHeader: true,
    airtimeContacts: null,
    nawecPaymentIntent: null,
    nawecToken: null,
    airtimePaymentIntent: null,
    snackbar: false,
    snackbarMessage: null,
    buyCashpowerFor: null,
    buyAirtimeFor: null,
    creatingIntent: false,
    mConfirmingIntent: false,
    layout: 'default',
    loadingConfig: true,
    loadingConfigError: false,
    payConfig: null,
    moneyTransferTypes: [],
    moneyTransferCollectionTypes: [],
    publisher: null,
    geo: null,
    referer: 'web',
    /* number:null,
    operator:null */
  },
  mutations: {
    mSetRouteParameters(state, route) {
      try {
        const query = route.query;
        let referer = query.r ? query.r : null;
        if(!referer) {
          referer = query.referer ? query.referer : null;
        }
        if(referer) {
          state.referer = referer;
        }
      } catch(error) {
        //
      }
    },
    mConfirmingIntent(state, creatingIntent) {
      state.creatingIntent = creatingIntent;
    },
    mCreatingIntent(state, creatingIntent) {
      console.log('creatingIntent', creatingIntent);
      state.creatingIntent = creatingIntent;
    },
    mShowAlert(state, message) {
      state.snackbarMessage = message;
      state.snackbar = true;
    },
    mCloseAlert(state) {
      state.snackbarMessage = null;
      state.snackbar = false;
    },
    mSetLayout(state, layout) {
      state.layout = layout;
      if (layout == 'app') {
        saveInStorage('OPENED_FROM_APP', 'Y');
      }
    },
    mSetHeader(state, data) {
      state.previousHeader = data;
    },
    mSetPageHeader(state, data) {
      state.previousHeader = state.showHeader;
      state.showHeader = data;
    },
    mRevertSetHeader(state) {
      state.showHeader = state.previousHeader;
    },
    mSetPublisher(state, publisher) {
      state.publisher = publisher;
    },
    mSetPayConfig(state, config) {
      state.payConfig = config;

      state.exchangeRates = config.exchangeRates;
    },
    SET_CONTACT_TYPE(state, data) {
      state.contactType = null;
      state.contactType = data;
    },
    SET_CONTACT_LIST(state, data) {
      // console.log('meter numbers')
      state.contacts = null;
      let list = [];
      if (data) {
        list = data.filter((item) => item.name && (item.meterNo || item.mobile)).map((item) => {
          if(item.meterNo){
            const index = item.name + ' ' + item.meterNo;
          item.index = index.toLowerCase();
          }else if(item.mobile){
            const index = item.name + ' ' + item.mobile;
          item.index = index.toLowerCase();
          }
          return item;
        });
      }
      state.contacts = list;
      // console.log('contacts', list)
    },
    // SET_RECIPIENT(state, data){
    //   state.buyCashpowerFor = null;
    //   state.buyAirtimeFor = null;
    //   if(state.contactType === 'NAWEC'){
    //     state.buyCashpowerFor = data;
    //   }else if(state.contactType === 'AIRTIME'){
    //     state.buyAirtimeFor = data;
    //   }
    // },
    // SET_CONTACT_TYPE(state, data) {
    //   state.contactType = null;
    //   state.contactType = data;
    // },
    // SET_CONTACT_LIST(state, data) {
    //   console.log('meter numbers')
    //   state.contacts = null;
    //   let list = [];
    //   if (data) {
    //     list = data.filter((item) => item.name && (item.meterNo || item.mobile)).map((item) => {
    //       if(item.meterNo){
    //         const index = item.name + ' ' + item.meterNo;
    //       item.index = index.toLowerCase();
    //       }else if(item.mobile){
    //         const index = item.name + ' ' + item.mobile;
    //       item.index = index.toLowerCase();
    //       }
    //       return item;
    //     });
    //   }
    //   state.contacts = list;
    //   console.log('contacts', list)

    //   // state.meterNumberContacts = list;
    // },
    SET_NAWEC_PAYMENT_INTENT(state, data) {
      state.nawecPaymentIntent = null;
      state.nawecPaymentIntent = data;
    },
    UPDATE_NAWEC_PRETTY_TOKEN(state, data){
      state.nawecToken = null;
      state.nawecToken = data;
    },
    SET_AIRTIME_PAYMENT_INTENT(state, data) {
      state.airtimePaymentIntent = null;
      state.airtimePaymentIntent = data;
    },
    mSetGeo(state) {
      state.geo =  getFromStorage(GEO_DETAILS);
      if(state.geo && state.geo.country) {
        let fromCurrencyCode = getCurrency(state.geo.country);
        // alert("GEO " + state.geo.country);
        // alert(fromCurrencyCode);
        // fromCurrencyCode = 'GMD';
        this.commit("basket/mSetFromCurrencyCode", { fromCurrencyCode }, { root: true });
        this.commit("commerce/mSetDefaultCurrencyCode", fromCurrencyCode, { root: true });
        if(state.geo.dialCode) {
          this.commit("basket/mSetDialCode", { dialCode: state.geo.dialCode }, { root: true });
        }
        // 
      }
      
    },
    // SET_AIRTIME_NUMBER_LIST(state, data) {
    //   state.airtimeContacts = null;
    //   let list = [];
    //   if (data) {
    //     list = data.filter((item) => item.name && item.mobile).map((item) => {
    //       const index = item.name + ' ' + item.mobile;
    //       item.index = index.toLowerCase();
    //       return item;
    //     });
    //   }
    //   state.meterNumberContacts = data;
    //   console.log('airtime numbers', list)
    //   // state.airtimeContacts = list;
    // }
    /*     setNumber(state, number, operator){
          state.number = number
          state.operator= operator
        }, */
    /* setOperator(state, operator){
      state.operator = operator
    } */
  },
  getters: {
    isAuthenticated: () => true,
  },
  actions: {
    aInit({ dispatch }) {
      try {
        dispatch("aLoadConfig");
        dispatch("aLoadGeo");
      } catch(error) {
        //
        // error
      }
    },
    performLogin() {},
    async aLoadConfig( { state, commit }) {
      try {
        // alert("hey");
        state.loadingConfigError = false;
        state.loadingConfig = true;
        const response = await ConfigService.getConfig();
        commit("mSetPayConfig", response.data);
        commit("basket/mSetConfig", response.data, { root: true });
        commit("sendmoney/mSetConfig", response.data, { root: true });
        commit("airtime/mSetConfig", response.data, { root: true });
        commit("cashpower/mSetConfig", response.data, { root: true });

      } catch (error) {
        state.loadingConfigError = false;
        //
       //  state.loadingConfig = true;
      }
      state.loadingConfig = false;
    },
    async aLoadGeo( { commit }) {
      try {
        const d = getFromStorage(GEO_DETAILS);
        if(d) {
          const result = browser(); 
          result.channel = result.mobile ? 'mobile' : 'web';
          const geoDetails = { ...result, ...d}
          geoDetails.dialCode = getDialCode(geoDetails.country);
          saveInStorage(GEO_DETAILS, geoDetails);
        } else {
          const result = browser(); 
          result.channel = result.mobile ? 'mobile' : 'web';
          // console.log('aLoadGeo result', result);
          const response = await getGeo();
          const geoDetails = { ...result, ...response.data}
          geoDetails.dialCode = getDialCode(geoDetails.country);
          saveInStorage(GEO_DETAILS, geoDetails);
          // console.log('aLoadGeo response', geoDetails);
        }
      } catch(error) {
        //
      }
      commit('mSetGeo');
    },
    async loadContacts(store, payload) {
      // console.log('type', payload.type);
      try {
        const result = await ContactService.getContacts(payload.type);
        if (result.data) {
          console.log('resulttt', result.data)
          store.commit("SET_CONTACT_LIST", result.data);
          // if (payload.type === 'NAWEC') 
          //   store.commit("SET_CONTACT_LIST", result.data);
          // } else if (payload.type === 'AIRTIME') {
          //   store.commit("SET_AIRTIME_NUMBER_LIST", result.data);
          // }
        }
      } catch (error) {
        console.log("error", error);
      }

    },
    async createNawecPaymentIntent(store, data) {
      try {
        const payload = {
          ...data
        };
        // if (typeof payload.fromWalletCode == "undefined" || !payload.fromWalletCode) {
        //   payload.fromWalletCode = store.state.wallet.code;
        // }
        const result = await NAWECService.createNawecPaymentIntent(payload);
        // console.log('result', result)
        if (result && result.status) {
          store.commit("SET_NAWEC_PAYMENT_INTENT", result);
        } else {
          store.commit("SET_NAWEC_PAYMENT_INTENT", null);
        }
      } catch (error) {
        console.log("error", error.message);
        store.commit("SET_NAWEC_PAYMENT_INTENT", null);

      }

    },
    async confirmNawecPaymentIntent(store, data) {
      try {
        const payload = {
          ...data
        };
        // if (typeof payload.fromWalletCode == "undefined" || !payload.fromWalletCode) {
        //   payload.fromWalletCode = store.state.wallet.code;
        // }
        const result = await NAWECService.confirmNawecPaymentIntent(payload);
        if (result && result.status) {
          // const data 
          // console.log('result', result)
          const secret = result.data.secret;
          store.dispatch("getPaymentIntentUsingSecret", secret);
        } else {
          console.log('No Internet Connection')
        }
      } catch (error) {
        console.log("error", error.message);

      }
    },
    async getPaymentIntentUsingSecret(store, secret) {
      try {
        const result = await NAWECService.getIntent(secret);
        if (result.data) {
          store.commit("UPDATE_NAWEC_PRETTY_TOKEN", result.data);
        } else {
          console.log('data unavailiable')
          return null
        }
      } catch (error) {
        console.log("error", JSON.stringify(error));
      }

    },
    async sendNawecToken(store, payload) {
      // console.log(payload)
      try {
        const result = await NAWECService.sendNawecToken(payload);
        console.log('result', result)
        // if (result) {
        //   store.commit("SET_NAWEC_PAYMENT_INTENT", result);
        // }
      } catch (error) {
        // console.log("error", error);
      }

    },
    async createAirtimePaymentIntent(store, data) {
      try {
        const payload = {
          ...data
        };
        // if (typeof payload.fromWalletCode == "undefined" || !payload.fromWalletCode) {
        //   payload.fromWalletCode = store.state.wallet.code;
        // }
        const result = await AirtimeService.createAirtimePaymentIntent(payload);
        console.log('result', result)
        if (result && result.status) {
          store.commit("SET_AIRTIME_PAYMENT_INTENT", result);
        } else {
          console.log('No Internet connection');
          store.commit("SET_AIRTIME_PAYMENT_INTENT", null);
        }
      } catch (error) {
        console.log("error", error.message);
        store.commit("SET_AIRTIME_PAYMENT_INTENT", null);

      }

    },
    async confirmAirtimePaymentIntent(store, data) {
      try {
        const payload = {
          ...data
        };
        // if (typeof payload.fromWalletCode == "undefined" || !payload.fromWalletCode) {
        //   payload.fromWalletCode = store.state.wallet.code;
        // }
        const result = await AirtimeService.confirmAirtimePaymentIntent(payload);
        if (result && result.status) {
          // const data 
          // console.log('result', result)
          return result;
        } else {
          console.log('No Internet connection');
        }
      } catch (error) {
        console.log("error", error.message);

      }
    },
  },
});

export {
  store,
};