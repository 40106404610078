
const initialState = {

  tags: [],
  userTags: [],
  exchangeRateConfigs:[]

}; 

export default {
  ...initialState
}
