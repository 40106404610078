
const initialState = {
  paymentIntent: null,
  paymentTransactions: [],
  paymentParams: null,
  customer: null,
  paymentDialog: false,
  paymentID: null,
  topUpDialog: false,
  fromCurrencyCode: 'GBP',
  toCurrencyCode: 'GMD',
  paymentMethod: 'card',
  cards: null,
  topUpKey: null,
  payGroups: [],
  addGroupDialog: false,
  updateGroupDialog: false,
  beneficiaryDialog: false,
  addBankDialog: false,
  updateBeneficiaryDialog: false,
  erroredDialog:false,
  isEditing: false,
  loading: false,
  payGroup: [],
  loans: [],
  users: [],
  totalUsers: 0,
  totalPayGroups: 0,
  totalLoans: 0,
  totalMembers: 0,
  groupPaySummary: [],
  beneficiaries: [],
  banks:[],
  bank: [],
  bankRecords: [],
  payoutSettlements: [],
  tags: [],
  userTags: [],
 /*  beneficiaryDetails:{},
  recipient:{}, */
/*   sendMoneyPayload: {
    deliveryType: null,
    amount: null,
    toCurrencyCode: "GMD",
    fromCurrencyCode: "GMD",
    fromWalletCode: null,
    fromAmountNet: null,
    fromAmount: null,
    transferType: "WALLET",
    transferCharge: 0.0,
    deliveryCharge: 0.0,
    exchangeRate: 1,
    rate: 1,
    toAmount: null,
    internationalTransfer: false,
  } */
}; 

export default {
  ...initialState
}
