<template>
  <v-row justify="">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
      :fullscreen="isMobile"
    >
      <v-card class="">
        <v-card-title>
          <h2 class="subtitle-1">Buy Cash Power</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="mUpdateBuyCashpowerDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <Stepper :params="{ detail: cashpowerParams }" />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
// import { devNull } from 'os';
const cashpowerNamespace = createNamespacedHelpers("cashpower");
export default {
  components: {
    Stepper: () => import("@/components/cashpower/buy-stepper"),
  },
  computed: {
    ...cashpowerNamespace.mapState({
      loading: (state) => state.loading,
      cashpowerDialog: (state) => state.cashpowerDialog,
      cashpowerParams: (state) => state.cashpowerParams,
      serverConfig: (state) => state.serverConfig,
      resend: (state) => state.resend,
    }),
    dialog: {
      get() {
        return this.cashpowerDialog;
      },
      set(value) {
        this.mUpdateBuyCashpowerDialog(value);
      },
    },
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
  },
  methods: {
    ...cashpowerNamespace.mapMutations([
      "mBuyCashpower",
      "mUpdateBuyCashpowerDialog",
      "mSetReciepientNumber",
      "mSetResend",
      "mResetParams",
    ]),
  },
};
</script>

<style scoped>

</style>
