<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title>
          <h2 class="subtitle-1">Switch Accounts</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="mSetListWalletDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-list subheader two-line class="pa-2">
          <template v-if="wallets && wallets.length">
            <v-list-item v-for="item in wallets" :key="item.code" class="pointer" @click="setWallet(item)">
              <v-list-item-content class="pointer">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.currentBalance | currency(item.currency + " ")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon v-if="wallet.code === item.code">
                  <v-icon color="primary">radio_button_checked</v-icon>
                </v-btn>
                <v-btn icon v-else>
                  <v-icon color="grey">radio_button_checked</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
// import { devNull } from 'os';
const cashpowerNamespace = createNamespacedHelpers("cashpower");
const usersNamespace = createNamespacedHelpers("users");

export default {
  components: {},
  computed: {
    ...cashpowerNamespace.mapState({
      loading: (state) => state.loading,
      cashpowerDialog: (state) => state.cashpowerDialog,
      cashpowerParams: (state) => state.cashpowerParams,
      serverConfig: (state) => state.serverConfig,
      resend: (state) => state.resend,
    }),
    ...usersNamespace.mapState({
      listWalletDialog: (state) => state.listWalletDialog,
      wallets: (state) => state.wallets,
      wallet: (state) => state.wallet,
    }),
    dialog: {
      get() {
        return this.listWalletDialog;
      },
      set(value) {
        this.mSetListWalletDialog(value);
      },
    },
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
  },
  methods: {
    ...cashpowerNamespace.mapMutations([
      "mBuyCashpower",
      "mUpdateBuyCashpowerDialog",
      "mSetReciepientNumber",
      "mSetResend",
      "mResetParams",
    ]),
    setWallet(wallet) {
        this.mSetWallet(wallet);
        console.log("switch")
        this.mSetListWalletDialog(false);
        this.$router.push({ name: "admin-index" });
    },
    ...usersNamespace.mapMutations(["mSetListWalletDialog", "mSetWallet"]),
  },
};
</script>
<style scoped>
.pointer {
    cursor: pointer;
}
.pointer:hover {
    background-color: #F5F5F5;
}
</style>