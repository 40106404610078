import Factory from '@/services/factory';
import PublicPayService from '@/services/public-pay.service';
import PayService from '../../../services/pay.service';
import ReportService from '../../../services/report.service';

export default {
  async aCreatePaymentIntent({ state, commit }, payload) {
    try {
      const response = await Factory.get("PayService").createIntent(payload);
      const data = response.data;
      state.paymentIntent = data;
    } catch (error) {
      let message;
      try {
        message = error.response.data.message;
      } catch(error) {
        message = "Error => Transaction failed "
      }
      commit("mShowAlert", message, {
        root: true,
      });
      throw message;
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async aGetPaymentIntent({}, secret) {
    try {
      const response = await Factory.get("PayService").getIntent(secret);
      const data = response.data;
      return data;
    } catch (error) {
      // 
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async aCalculateFrom({}, payload) {
    try {
      const response = await PublicPayService.calculateFrom(payload);
      const data = response.data;
      return data;
    } catch (error) {
      // 
      // alert(error);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async aCalculateTo({}, payload) {
    try {
      const response = await PublicPayService.calculateTo(payload);
      const data = response.data;
      return data;
    } catch (error) {
      // 
    }
  },
  async aCustomer({ state }) {
    try {
      const response = await Factory.get("PayService").customer();
      const data = response.data;
      state.customer = data;
    } catch (error) {
      // 
    }
  },
  async aGetPayGroups({ state}, walletCode) {
    try{
      const response = await PayService.listPaymentGroups(walletCode);
      const data = response.data;
      state.payGroups = data;
      if(data.length > 0){
        state.totalPayGroups = data.length;
        console.log("state.totalPayGroups", state.totalPayGroups);
      }
    }catch(error){
      this.$store.commit("mShowAlert", error);
    }
  },

  async aGetLoans({ state }) {
    try {
      const response = await ReportService.getReport("loan_applications");
      const data = response.data.items;
      state.loans = data;
      if(Array.isArray(data)){
        state.totalLoans = data.length
        //console.log("state.totalLoans", state.totalLoans);
      }
  } catch (error) {
    this.$store.commit("mShowAlert", error);
  }
},

async aGetMembers({ state }) {
  try {
    const response = await ReportService.getReport("acl_tenant_users");
    const data = response.data.items;
    state.users = data;
    if(Array.isArray(data)){
      state.totalUsers = data.length
    }
  } catch (error) {
    this.$store.commit("mShowAlert", error);
  }
},

async aGetSummary({ state, commit }, code) {
  try {
    const response = await ReportService.getReport(
      "grouppaymentbeneficiary_summary",
      {
        grouppayment_code: code,
      }
    );
    state.groupPaySummary = response.data.items
  } catch (error) {
    commit("mShowAlert", error);
  }
},

async aGetTags({ state }) {
  try{
    const response = await ReportService.getReport("pay_tags");
    const data = response.data.items;
    state.tags = data;
    console.log("aGetTags data", data);
  }catch(error){
    this.$store.commit("mShowAlert", error);
  }
},

async aGetUserTags({ state }) {
  try{
    const response = await ReportService.getReport("pay_tags", {
      type: "USER",
    });
    const defaultTag = {code: "*", name: "All Users"};
    const data = response.data.items;
    state.userTags = [...[defaultTag], ...data];
    //state.userTags = data;
    console.log("aGetUserTags data", data);
  }catch(error){
    this.$store.commit("mShowAlert", error);
  }
},

async aGetBanks({ state }) {
  try {
    const response = await ReportService.getReport("external_bank_accounts");
    const data = response.data.items;
    console.log("aGetBanks data", data);
    state.banks = data;
  } catch (error) {
    this.$store.commit("mShowAlert", error);
  }
},


async aGetBank({ state }, code) {
  try {
    const response = await ReportService.getReport("external_bank_accounts", {
      code: code,
    });
    const data = response.data.items;
    for (let i = 0; i < data.length; i++) {
      if (data[i].code === code) {
        state.bank = data[i];
        break;
      }
    }
    //state.bank = data;
    console.log("aGetBank bank data", state.bank);
  } catch (error) {
    this.$store.commit("mShowAlert", error);
  }
},

async aGetSettlements({ state }) {
  try {
    const response = await ReportService.getReport("get_settlement_balances");
    const data = response.data.items;
    console.log("aGetSettlements data", data);
    state.settlements = data;
  } catch (error) {
    this.$store.commit("mShowAlert", error.message.data);
    //console.log("error", error);
    //this.$store.commit("mShowAlert", "error occured");
  }
},
async aGetBankRecords({ state }, code) {
  try {
    const response = await ReportService.getReport("external_bank_account_transactions", {
      account_code: code,
    });
    const data = response.data.items;
    console.log("aGetBankRecords data", data);
    state.bankRecords = data;
  } catch (error) {
    this.$store.commit("mShowAlert", error);
  }
},
  async aGetPayGroup({ state},code) {
    try{
      const response = await PayService.getPaymentGroup(code);
      const data = response.data;
      console.log('aGetPayGroup data', data);
      state.payGroup = data;
    }catch(error){
      this.$store.commit("mShowAlert", error);
    }
  },
  async aGetPayGroupBeneficiaries({ state }, code) {
    try{
      const response = await PayService.getPaymentGroupBeneficiaries(code);
      const data = response.data;
      //console.log('aGetPayGroup data', data);
      state.beneficiaries = data;
      
      console.log("state.beneficiaries", state.beneficiaries);
    }catch(error){
      this.$store.commit("mShowAlert", error);
    }
  },
  aUpdatePayGroup({ state}, payload) {
    state.loading = false;
    return new Promise((resolve, reject) => {
      PayService.updatePaymentGroup(payload)
        .then(function() {
         // dispatch("aUpdateUser");
          console.log("user pay group updated")
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  async aGetCards({ state }) {
    try {
      const response = await Factory.get("PayService").getCards();
      const data = response.data;
      console.log('aGetCards data', data);
      state.cards = [];
      const cards = data.map((item) => {
          const result = {};
          result.id = item.id;
          result.name = `•••• ${item.card.last4} - Expiry ${item.card.expMonth}/${item.card.expYear} (${item.card.country})`;
          return result;
        });
        state.cards = cards;
      // state.cards = data.map((item) => {
      //   const result = {};
      //   result.id = item.id;
      //   result.name = `•••• ${item.methodDetails.last4} - Expiry ${item.methodDetails.expiryMonth}/${item.methodDetails.expiryYear}`;
      //   return result;
      // });
    } catch (error) {
      // 
    }
  },

  async aGetPayouts({state}){
    try{
      const response= await Factory.get("PayService").getPayouts();
      const data = response.data
      state.payouts = data.map((item)=>{
        const result = {};
        result.id = item.id;
        console.log(result)
        return result
      });
    }catch(error){
      //
    }
  },
  async aConfirmPaymentIntent({ state }, payload) {
    try {
      await Factory.get("PayService").confirmIntent(payload);
      // const data = response.data;
      state.paymentIntent = null;
    } catch (error) {
      // 
    }
  },
  async aConfirmWalletTransaction({ state, commit }, payload) {
    try {
      await Factory.get("PayService").confirmWalletTransaction(payload);
      // const data = response.data;
      state.paymentIntent = null;
    } catch (error) {
      let message;
      try {
        message = error.response.data.message;
      } catch(error) {
        message = "Error => Transaction failed "
      }
      commit("mShowAlert", message, {
        root: true,
      });
      throw new Error(message);
    }
  },
  async aPayInvoice({ state }, payload) {
    try {
      await Factory.get("PayService").payInvoice(payload);
      // const data = response.data;
      state.paymentIntent = null;
    } catch (error) {
      let message;
      try {
        message = error.response.data.message;
      } catch(error) {
        message = "Error => Transaction failed "
      }
      // commit("mShowAlert", message, {
      //   root: true,
      // });

      throw new Error(message);
    }
  },

}
