<template>
  <div class="no-print">
    <div >
      <v-app-bar
        color="transparent"
        
        elevation="0"
        extension-height="20"
        style="z-index: 90"
      >
        <v-app-bar-nav-icon
          class="ml-2"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-spacer></v-spacer>
        <topupBtn />
        <div
          class="ma-1 pt-8 text-center"
          v-if="ability && ability.can('read', 'SENDMONEY')"
        >
          <v-btn color="indigo darken-1" fab small dark @click="mSendMoney()">
            <v-icon>send</v-icon>
          </v-btn>
          <div class="mt-2 caption">Send Money</div>
        </div>
        <div
          class="ma-1 pt-8 text-center"
          v-if="ability && ability.can('read', 'BUYCASHPOWER') "
        >
          <v-btn color="green darken-1" fab small dark @click="mBuyCashpower()">
            <v-icon>bolt</v-icon>
          </v-btn>
          <div class="mt-2 caption">Cash Power</div>
        </div>
        <div
          class="mr-3 ma-1 pt-8 text-center"
          align="top"
          v-if="ability && ability.can('read', 'BUYAIRTIME')"
        >
          <v-btn
            class="pa-2 white--text"
            color="blue darken-1"
            fab
            small
            dark
            @click="mBuyAirtime()"
          >
            <v-icon dark>send_to_mobile</v-icon> </v-btn
          ><br />
          <div class="mt-2 caption">Airtime</div>
        </div>
        <!-- <moreBtn /> -->
        <!--         <div
          class="ma-2 pt-12 text-center"
          v-if="ability && ability.can('read', 'BUYCASHPOWER')"
        >
          <v-menu
            bottom
            origin=" center"
            class="ma-2text-center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="more">
              <v-list-item>
                <v-list-item-title @click="mBuyGiftCard()"><v-icon>redeem</v-icon> Buy Gift Card</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="mt-3 caption">More</div>
        </div> -->

        <div
          class="ma-2 pt-8 text-center"
          v-if="ability && ability.can('read', 'SELLCASHPOWER')"
        >
          <v-btn
            color="green darken-1"
            fab
            small
            dark
            @click="mSellCashpower()"
          >
            <v-icon>bolt</v-icon>
          </v-btn>
          <div class="mt-2 caption">Cash Power</div>
        </div>
        <div
          class="mr-8 ma-2 pt-8 text-center"
          align="top"
          v-if="ability && ability.can('read', 'SELLAIRTIME')"
        >
          <v-btn
            class="pa-2 white--text"
            color="blue darken-1"
            fab
            small
            dark
            @click="mSellAirtime()"
          >
            <v-icon dark>send_to_mobile</v-icon> </v-btn
          ><br />
          <div class="mt-2 caption">Airtime</div>
        </div>

        <!--                 <div class="pr-7 ma-2 pt-6 text-center">
          <v-btn
            class="pa-2 white--text"
            color="blue darken-1"
            fab
            small
            dark
            
            @click="mLogin()"
          >
            <v-icon dark>account_circle</v-icon> </v-btn
          ><br />
          <span class="mb-4 caption">Login</span>
        </div> -->
        <!--  <div style="width: 100" class="pa-2">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ma-2"
                color="blue darken-1"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="30">account_circle</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  ><v-icon>person</v-icon> {{ user }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="logout()">
                <v-list-item-title
                  ><v-icon>logout</v-icon> Log Out</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <br />
          <span class="mb-2 caption">Profile</span>
        </div> -->
      </v-app-bar>
    </div>

    <v-navigation-drawer
      height="100vh"
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      app
      class="app primary"
      dark
    >
      <v-list >
        <v-list-item height="80" class="pa-3">
          <v-list-item-avatar size="40" color="white">
            <span class="blue--text caption" v-text="getFirstLetters()"></span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="wallet && selectedTenant == 'taybullpay' || selectedTenant == 'taybull-send'" class="caption"
              >{{ wallet.name }} ({{ wallet.publicCode }})</v-list-item-title
            >
            <v-list-item-title v-else-if="wallet" class=""
              >{{ wallet.fullname }}</v-list-item-title
            >
            <v-list-item-subtitle class="pt-1">{{
              wallet.currentBalance | currency(wallet.currency)
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <!-- <div class="ma-2 subtitle-1 white--text" v-if="wallet">{{ wallet.name }}</div> -->
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dark dense>
        <v-list-item active-class="white--text" link to="/admin/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Dashboard</v-list-item-title
          >
        </v-list-item>
       <!--  <v-list-item
          active-class="white--text"
          link
          to="/admin/receipt/receipt-upload"
          
        >
          <v-list-item-icon>
            <v-icon>upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Upload Receipt</v-list-item-title
          >
        </v-list-item> -->
        <!--          <v-list-item
          active-class="white--text"
          link
          to="/admin/send-money/bank-payout"
          v-if="ability && ability.can('read', 'MANAGEAGENCIES')"
        >
          <v-list-item-icon>
            <v-icon>payments</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Bank Pay Outs</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item
          v-if="ability && ability.can('read', 'PAYOUT') "
          active-class="white--text"
          link
          to="/admin/send-money/payout"
        >
          <v-list-item-icon>
            <v-icon>payments</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Pay Outs</v-list-item-title
          >
        </v-list-item>
        <v-list-group
          v-if="ability && ability.can('read', 'SENDMONEY')"
          no-action
          :value="false"
          active-class="white--text"
          prepend-icon="send"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold"
                >Money Transfer</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="white--text"
            link
            to="/admin/send-money/transactions"
          >
            <v-list-item-title class=".font-weight-bold"
              >Transactions</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/send-money/contacts"
          >
            <v-list-item-title class=".font-weight-bold"
              >Contacts</v-list-item-title
            >
          </v-list-item>

          <!--     <v-list-item
            active-class="white--text"
            link
            to="/admin/send-money/payout-details"
          >
            <v-list-item-title class=".font-weight-bold"
              >Payout Details</v-list-item-title
            >
          </v-list-item> -->
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          active-class="white--text"
          prepend-icon="bolt"
          v-if="
            ability &&
            (ability.can('read', 'SELLCASHPOWER') ||
              ability.can('read', 'BUYCASHPOWER')) 
          "
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold"
                >Cash Power</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="white--text"
            link
            to="/admin/cashpower/transactions"
          >
            <v-list-item-title class=".font-weight-bold"
              >Transactions</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/cashpower/contacts"
          >
            <v-list-item-title class=".font-weight-bold"
              >Contacts</v-list-item-title
            >
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="
            ability &&
            (ability.can('read', 'SELLAIRTIME') ||
              ability.can('read', 'BUYAIRTIME')) 
          "
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold"
                >Airtime</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/airtime/transactions"
          >
            <v-list-item-title class=".font-weight-bold"
              >Transactions</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/airtime/contacts"
          >
            <v-list-item-title class=".font-weight-bold"
              >Contacts</v-list-item-title
            >
          </v-list-item>
        </v-list-group>
        
        <v-list-item
          active-class="white--text"
          link
          to="/admin/report/pps-reports"
          v-if="ability && ability.can('read', 'PPSREPORTS')"
        >
          <v-list-item-icon>
            <v-icon>business</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >PPS Reports</v-list-item-title
          >
        </v-list-item>
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="ability && ability.can('read', 'AGENCYREPORTS')"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold"
                >Report</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/payout-branch"
          >
            <v-list-item-title class=".font-weight-bold"
              >Payout Report</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/payout-cashier"
          >
            <v-list-item-title class=".font-weight-bold"
              >Cashier Pay Outs</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/payout-me"
          >
            <v-list-item-title class=".font-weight-bold"
              >My Pay Outs</v-list-item-title
            >
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="ability && ability.can('read', 'TENANTREPORTS')"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold"
                >Report</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/payout-agency"
          >
            <v-list-item-title class=".font-weight-bold"
              >Payout Report</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/awaiting-dispatch"
          >
            <v-list-item-title class=".font-weight-bold"
              >Awaiting Collection</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/pending-bank-payment"
          >
            <v-list-item-title class=".font-weight-bold"
              >Pending Bank Payments</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/pending-bill-payment"
          >
            <v-list-item-title class=".font-weight-bold"
              >Pending Bill Payments</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/bank-payouts"
          >
            <v-list-item-title class=".font-weight-bold"
              >Bank Pay Outs</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/bill-payouts"
          >
            <v-list-item-title class=".font-weight-bold"
              >Bill Pay Outs</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/tenant-transactions"
          >
            <v-list-item-title class=".font-weight-bold"
              >Service Transactions</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/user-signups"
          >
            <v-list-item-title class=".font-weight-bold"
              >User Sign Ups</v-list-item-title
            >
          </v-list-item>
        </v-list-group>
        <!-- Monitoring -->
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="ability && ability.can('read', 'MONITORING')"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold">Monitoring</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/active-activity-monitor">
            <v-list-item-title class=".font-weight-bold">Active Transactions</v-list-item-title>
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/activity-monitor">
            <v-list-item-title class=".font-weight-bold">Transactions</v-list-item-title>
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/device-messages">
            <v-list-item-title class=".font-weight-bold">Device Messages</v-list-item-title>
          </v-list-item>
          
          <v-list-item
            active-class="white--text"
            link
            to="/admin/devices">
            <v-list-item-title class=".font-weight-bold">Devices</v-list-item-title>
          </v-list-item>

          <v-list-item
            active-class="white--text"
            link
            to="/admin/configs">
            <v-list-item-title class=".font-weight-bold">Configs</v-list-item-title>
          </v-list-item>

        </v-list-group>
        <!-- Logs -->
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="ability && ability.can('read', 'LOGS')"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold">Logs</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/report/payment-logs">
            <v-list-item-title class=".font-weight-bold">Payments</v-list-item-title>
          </v-list-item>
        </v-list-group>
        
         <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="ability && ability.can('read', 'MANAGEMESSAGING')"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold">Messaging</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/messaging/push-notifications">
            <v-list-item-title class=".font-weight-bold">Push Notification</v-list-item-title>
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/messaging/whatsapp">
            <v-list-item-title class=".font-weight-bold">WhatsApp</v-list-item-title>
          </v-list-item>


        </v-list-group>
         <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="groups"
        ><template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold-normal"
                >Bulk Transactions</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item active-class="white--text" link to="/admin/group-payment/group-payments/money-transfer">
            <v-list-item-title class=".font-weight-bold-normal"
              >Bulk Money Transfer</v-list-item-title
            >
          </v-list-item>
          <v-list-item active-class="white--text" link to="/admin/group-payment/group-payments/airtime">
            <v-list-item-title class=".font-weight-bold-normal"
              >Bulk Airtime</v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item active-class="white--text" link to="/admin/group-payment/group-payments/cashpower">
            <v-list-item-title class=".font-weight-bold-normal"
              >Bulk Cashpower</v-list-item-title
            >
          </v-list-item> -->
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="send_to_mobile"
          v-if="ability && ability.can('read', 'COMPLIANCE') && user.tenant === 'taybullpay'"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold">Compliance</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/compliance">
            <v-list-item-title class=".font-weight-bold">Users</v-list-item-title>
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/activity-monitor">
            <v-list-item-title class=".font-weight-bold">Settings</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
            active-class="white--text"
            link
            to="/admin/bank/banks"
            v-if="ability && ability.can('read', 'MANAGEUSERS')"
            
          >
          <v-list-item-icon>
            <v-icon>groups</v-icon>
          </v-list-item-icon>
            <v-list-item-title class=".font-weight-bold-normal"
              >Bank Accounts</v-list-item-title
            >
          </v-list-item>

        <v-list-item
          active-class="white--text"
          link
          to="/admin/tenant-users"
          v-if="ability && ability.can('read', 'MANAGEUSERS') "
        >
          <v-list-item-icon>
            <v-icon>group</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Users</v-list-item-title>
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
          to="/admin/generate-qrcode"
          v-if="ability && ability.can('read', 'MANAGEUSERS') && selectedTenant == 'taybullpay' "
        >
          <v-list-item-icon>
            <v-icon>qr_code</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Generate QR Code</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item
          active-class="white--text"
          link
          to="/admin/circles"
          v-if="ability && ability.can('read', 'MANAGEUSERS') ||ability && ability.can('read', 'INNOVARX')"
        >
          <v-list-item-icon>
            <v-icon>groups</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Circles</v-list-item-title>
        </v-list-item> -->

        <!--         <v-list-item
          active-class="white--text"
          link
          to="/admin/sub-agents"
          v-if="
            ability &&
            (ability.can('read', 'SELLAIRTIME') ||
              ability.can('read', 'BUYAIRTIME'))
          "
        >
          <v-list-item-icon>
            <v-icon>support_agent</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Agents</v-list-item-title>
        </v-list-item> -->
        <v-list-item
          active-class="white--text"
          link
          to="/admin/agencies"
          v-if="ability && ability.can('read', 'MANAGEAGENCIES')"
        >
          <v-list-item-icon>
            <v-icon>business</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Agencies</v-list-item-title
          >
        </v-list-item>
      <!--   <v-list-item
          active-class="white--text"
          link
          to="/admin/companies"
          v-if="ability && ability.can('read', 'INNOVARX')"
        >
          <v-list-item-icon>
            <v-icon>business</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Companies</v-list-item-title
          >
        </v-list-item> -->
 
        <!--   <v-list-item
          active-class="white--text"
          link
          to="/admin/companies"
          v-if="ability && ability.can('read', 'MANAGEAGENCIES')"
        >
          <v-list-item-icon>
            <v-icon>swap_horiz</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Companies</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item
          active-class="white--text"
          link
          :to="'/admin/agency/' + user.company"
          v-if="ability && ability.can('read', 'MANAGEAGENCY')"
        >
          <v-list-item-icon>
            <v-icon>supervisor_account</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Admin</v-list-item-title>
        </v-list-item>
      <!--   <v-list-item
          active-class="white--text"
          link
          :to="'/admin/agency/' + user.company"
          v-if="ability && ability.can('read', 'INNOVARX')"
        >
          <v-list-item-icon>
            <v-icon>supervisor_account</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Circle</v-list-item-title>
        </v-list-item> -->
  <!--       <v-list-item
          active-class="white--text"
          link
          :to="'/admin/agency/' + user.company"
          v-if="ability && ability.can('read', 'INNOVARX')"
        >
          <v-list-item-icon>
            <v-icon>supervisor_account</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold">Admin</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item
          active-class="white--text"
          link
          to="/admin/branches"
          v-if="ability && ability.can('read', 'MANAGEBRANCHES')"
        >
          <v-list-item-icon>
            <v-icon>swap_horiz</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Branches</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item
          active-class="white--text"
          link
          to="/admin/payout/settlements"
          v-if="ability && ability.can('read', 'MANAGEEXCHANGERATES')"
        >
          <v-list-item-icon>
            <v-icon>currency_exchange</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Settlements</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          active-class="white--text"
          link
          to="/admin/exchange-rates"
          v-if="ability && ability.can('read', 'MANAGEEXCHANGERATES')"
        >
          <v-list-item-icon>
            <v-icon>currency_exchange</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Exchange Rate</v-list-item-title
          >
        </v-list-item>

                <v-list-item
          active-class="white--text"
          link
          to="/admin/api-keys"
          v-if="ability && ability.can('read', 'CANCREATEAPIKEYS')"
        >
          <v-list-item-icon>
            <v-icon>group</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >API Keys</v-list-item-title
          >
        </v-list-item>

<!--         <v-list-item
          active-class="white--text"
          link
          to="/admin/documents/upload-document"
        >
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Profile</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item
          active-class="white--text"
          link
          to="/admin/user-profile/profile"
        >
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Profile</v-list-item-title
          >
        </v-list-item>
   <!--      <v-list-item
          active-class="white--text"
          link
          to="/admin/documents/user-profile"
        >
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >User Profile</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item
          active-class="white--text"
          link
          to="/admin/wallet-transfer"
          v-if="ability && ability.can('read', 'WALLETTRANSFER') "
        >
          <!--   -->
          <v-list-item-icon>
            <v-icon>swap_horiz</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Wallet Transfer</v-list-item-title
          >
        </v-list-item>

        <!--        <v-list-item
          active-class="white--text"
          link
          to="/admin/send-money/payout-receipt"
        >
          <v-list-item-icon>
            <v-icon>payments</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Payout Receipt</v-list-item-title
          >
        </v-list-item> -->

        <!-- <v-list-group
          no-action
          :value="false"
          active-class="white--text"
          prepend-icon="receipt"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold"
                >Invoicing</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/invoice/items/list"
          >
            <v-list-item-title class=".font-weight-bold"
              >Items</v-list-item-title
            >
          </v-list-item>
          <v-list-item active-class="white--text" link to="/admin/invoice/main">
            <v-list-item-title class=".font-weight-bold"
              >Invoices</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/invoice/customers"
          >
            <v-list-item-title class=".font-weight-bold"
              >Customers</v-list-item-title
            >
          </v-list-item>
        </v-list-group> -->

        <!--         <v-list-item active-class="white--text" link to="/admin/invoice">
          <v-list-item-icon>
            <v-icon>receipt</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Invoice</v-list-item-title
          >
        </v-list-item> -->

        <v-list-item
          active-class="white--text"
          @click="switchAccounts()"
          v-if="wallets && wallets.length > 1"
        >
          <v-list-item-icon>
            <v-icon>swap_horiz</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Switch Account</v-list-item-title
          >
        </v-list-item>

        <v-list-group
          no-action
          :value="false"
          color=""
          prepend-icon="settings"
          v-if="ability && ability.can('read', 'LOGS')"
        >
          <template v-slot:activator>
            <v-list-item-content active-class="white--text">
              <v-list-item-title class=".font-weight-bold">Settings</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="white--text"
            link
            to="/admin/tags/tags">
            <v-list-item-title class=".font-weight-bold">Tags</v-list-item-title>
          </v-list-item>
   <!--        <v-list-item
            active-class="white--text"
            link
            to="/admin/config/service-config">
            <v-list-item-title class=".font-weight-bold">Exchange Rate Configs</v-list-item-title>
          </v-list-item> -->
        </v-list-group>

        <switchWallet />
        <v-list-item active-class="white--text" link to="/" >
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-title class=".font-weight-bold"
            >Back to Website</v-list-item-title
          >
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-1">
          <!-- <v-btn text block to="/send-money" class="pb-1"> back to home </v-btn> -->
          <div v-if="user" class="pl-5 white--text caption">
            {{ user.fullname }} <br />
            {{ user.email ? user.email : user.username }}
          </div>
          <v-list-item link @click="logout()">
            <v-list-item-title class="pa-1 white--text .font-weight-bold title"
              ><v-icon color="white">logout</v-icon> Log Out</v-list-item-title
            >
          </v-list-item>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import { destroyUser } from "@/services/jwt.service.js";

import { createNamespacedHelpers } from "vuex";
const cashpowerNamespace = createNamespacedHelpers("cashpower");
const airtimeNamespace = createNamespacedHelpers("airtime");
const sendmoneyNamespace = createNamespacedHelpers("sendmoney");
const usersNamespace = createNamespacedHelpers("users");
const payment = createNamespacedHelpers("payment");
const giftcardNamespace = createNamespacedHelpers("giftcard");
//const wallet=()=>import('./walletsDialog.vue')
const switchWallet = () =>
  import("@tenant/components/AdminComponent/switch-wallet-btn.vue");
const topupBtn = () =>
  import("@tenant/components/AdminComponent/topup-btn.vue");
//const moreBtn = () => import("@tenant/components/AdminComponent/more-btn.vue");
import { TENANT } from "@/config";
//const tenant = TENANT;
export default {
  components: {
    // wallet,
    topupBtn,
    /* moreBtn, */
    switchWallet,
  },
  props: {
    invert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      right: null,
      drawer: false,
      selectedTenant: TENANT,
    };
  },
  computed: {
    ...usersNamespace.mapState({
      wallet: (state) => state.wallet,
      wallets: (state) => state.wallets,
      ability: (state) => state.ability,
    }),
    ...payment.mapState({
      fromCurrencyCode: (state) => state.fromCurrencyCode,
      toCurrencyCode: (state) => state.toCurrencyCode,
    }),
    fullname() {
      const name = this.$store.state.users.user.fullname;
      return name.toUpperCase();
    },
    tenant() {
      const tenant = this.$store.state.users.users.tenant;
      return tenant;
    },
    showHeader() {
      // const name = this.$store.state.users.user.fullname;
      // return name.toUpperCase();
      return this.$store.state.showHeader;
    },
    user() {
      // const name = this.$store.state.users.user.fullname;
      // return name.toUpperCase();
      return this.$store.state.users.user;
    },
  },
  mounted() {
    console.log("nav bar..", this.$store.state.users.user);
    console.log("this.walletsss", this.wallets);
  },
  methods: {
    switchAccounts() {
      this.mSetListWalletDialog(true);
    },
    async logout() {
      await this.aLogout();
      // destroyUser();
      this.$router.push({ name: "home" });
    },
    getFirstLetters() {
      const firstLetters = this.fullname
        .split(" ")
        .map((word) => word[0])
        .join("");

      return firstLetters;
    },
    initiateTopUp() {
      const params = {
        amount: 100,
        type: "TOPUP",
        title: "Top Up",
        buttonText: "Top Up",
        fromCurrency: this.fromCurrencyCode,
        toCurrency: this.toCurrencyCode,
        fromCurrencyCode: this.fromCurrencyCode,
        toCurrencyCode: this.toCurrencyCode,
        displayType: "DIALOG",
      };
      this.mStartTopUp({ params });
    },
    ...usersNamespace.mapActions(["aLogout"]),
    ...usersNamespace.mapMutations(["mSetListWalletDialog"]),
    ...cashpowerNamespace.mapMutations(["mBuyCashpower", "mSellCashpower"]),
    ...airtimeNamespace.mapMutations(["mBuyAirtime", "mLogin", "mSellAirtime"]),
    ...sendmoneyNamespace.mapMutations(["mSendMoney"]),
    ...payment.mapMutations(["mStartTopUp"]),
    ...giftcardNamespace.mapMutations(["mBuyGiftCard"]),
  },
};
</script>
<style scoped>
.app {
  z-index: 90 !important;
}
.account-balance {
  margin-left: 240px;
  margin-top: 70px;
}
.more {
  cursor: pointer;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
